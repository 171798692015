div
<template>
  <div class="flex flex-col gap-3">
    <!-- <h4>Header</h4> -->
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-2 w-full sm:w-2/3">
        <span>Supplier Credit Note Number</span>
        <vs-input class="w-full" v-model="formHeaderModels.cnNumber" />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Supplier Credit Note Date</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.cnDate" /> -->
        <datepicker
          class="w-full"
          :inline="false"
          name="Debit Note Date"
          placeholder="Select Debit Note Date"
          v-model="formHeaderModels.cnDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Return Number</span>
        <vs-input class="w-full" v-model="formHeaderModels.trNumber" disabled />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Return Date</span>
        <datepicker
          class="w-full"
          :inline="false"
          name="Tax Return Date"
          placeholder="Select Tax Return Date"
          v-model="formHeaderModels.trDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Settlement Method</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.settlementMethod" /> -->
        <multiselect
          class="selectExample"
          v-model="formHeaderModels.settlementMethod"
          :options="
            !settlementMethodOptions || settlementMethodOptions.length == 0
              ? []
              : settlementMethodOptions
          "
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder="Type to search"
          track-by="SettlementMethod"
          label="SettlementMethod"
          :disabled="false"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                {{ props.option.SettlementMethod }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Invoice Reference</span>
        <div class="flex gap-3 items-center">
          <multiselect
            class="w-1/2"
            v-model="formHeaderModels.taxInvoiceReference"
            :options="
              !taxInvoiceReferenceOptions ||
              taxInvoiceReferenceOptions.length == 0
                ? []
                : taxInvoiceReferenceOptions
            "
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder="Type to search"
            track-by="id"
            :custom-label="queryTaxInvoiceReference"
            :disabled="false"
            @select="onTaxInvoiceRefSelected"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span
                  v-if="props.option.tax_return_number && props.option.value"
                  class="option__title"
                >
                  {{ props.option.tax_return_number }} -
                  {{ priceFormat(props.option.remaining) }} -
                  {{ props.option.tax_name }}
                </span>
                <span
                  v-else-if="props.option.tax_return_number"
                  class="option__desc"
                >
                  {{ props.option.tax_return_number }}
                </span>
                <span v-else class="option__desc">
                  {{ props.option.label }}
                </span>
              </div>
            </template>
          </multiselect>
          <vs-input
            class="w-1/2"
            v-model="formHeaderModels.taxInvoiceReferenceCode"
            :disabled="
              this.formHeaderModels.taxInvoiceReference &&
              Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
            "
          />
        </div>
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Tax Invoice Reference Date</span>
        <datepicker
          class="w-full"
          :inline="false"
          name="Tax Invoice Reference Date"
          placeholder="Select Tax Invoice Reference Date"
          v-model="formHeaderModels.taxInvoiceReferenceRefDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>

      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Posting Date</span>
        <!-- <vs-input class="w-full" v-model="formHeaderModels.postingDate" /> -->
        <datepicker
          class="w-full"
          :inline="false"
          name="Posting Date"
          placeholder="Select Posting Date"
          v-model="formHeaderModels.postingDate"
          :disabled-dates="{
            from: new Date(),
          }"
        />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Note</span>
        <vs-input class="w-full" v-model="formHeaderModels.note" />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Attachment</span>
        <vs-input
          v-model="fileName"
          @change="onFileChange"
          class="w-full"
          type="file"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
    },
    settlementMethodOptions: {
      type: Array,
      default: () => [],
    },
    settlementMethod: {
      type: Object,
      default: () => null,
    },
    disabledDates: {
      from: new Date(),
    },
    poDate: {
      type: String,
      default: "",
    },
    taxInvoiceReferenceOptions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // this.formHeaderModels.trDate = this.poDate;
    if (
      this.settlementMethod &&
      Object.keys(this.settlementMethod).length > 0 &&
      this.settlementMethod.SettlementMethod !== ""
    ) {
      this.formHeaderModels.settlementMethod = this.settlementMethod;
    } else {
      this.formHeaderModels.settlementMethod = this.settlementMethodOptions[0];
    }
  },
  components: {
    datepicker: Datepicker,
  },
  data() {
    return {
      fileName: "",
      formHeaderModels: {
        cnNumber: "",
        cnDate: "",
        trNumber: "",
        trDate: "",
        settlementMethod: {},
        postingDate: "",
        note: "",
        taxInvoiceReference: {},
        taxInvoiceReferenceCode: "",
        taxInvoiceReferenceRefDate: "",
      },
      file: null,
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    queryTaxInvoiceReference({ tax_return_number, remaining, tax_name }) {
      if (!tax_return_number || !remaining || !tax_name) {
        return "";
      } else {
        return `${tax_return_number} - ${this.priceFormat(
          remaining
        )} - ${tax_name}`;
      }
    },
    onTaxInvoiceRefSelected(value) {
      if (!value) return;
      this.$store.commit("dnReturn/setTaxInvoiceRefence", value);
    },
  },
  watch: {
    isSubmit() {
      if (!this.isSubmit) {
        return;
      }

      this.$emit("updateModel", this.formHeaderModels);
      if (this.file) {
        this.$emit("updateFile", this.file);
      }
    },
    settlementMethod() {
      if (
        this.settlementMethod &&
        Object.keys(this.settlementMethod).length > 0 &&
        this.settlementMethod.SettlementMethod !== ""
      ) {
        this.formHeaderModels.settlementMethod = this.settlementMethod;
      } else {
        this.formHeaderModels.settlementMethod =
          this.settlementMethodOptions.find(
            (v) => v.SettlementMethod === "Payment"
          );
      }

      console.log("settlementMethod", this.formHeaderModels.settlementMethod);
    },
    settlementMethodOptions() {
      if (
        this.settlementMethod &&
        Object.keys(this.settlementMethod).length > 0 &&
        this.settlementMethod.SettlementMethod !== ""
      ) {
        this.formHeaderModels.settlementMethod = this.settlementMethod;
      } else {
        this.formHeaderModels.settlementMethod =
          this.settlementMethodOptions.find(
            (v) => v.SettlementMethod === "Payment"
          );
      }
    },
    "formHeaderModels.taxInvoiceReference"() {
      if (this.formHeaderModels.taxInvoiceReference) {
        this.formHeaderModels.taxInvoiceReferenceCode = "";
        this.formHeaderModels.taxInvoiceReferenceRefDate =
          this.formHeaderModels.taxInvoiceReference.date;
      } else {
        this.formHeaderModels.taxInvoiceReferenceRefDate = null;
      }
    },
    // poDate() {
    //   this.formHeaderModels.trDate = this.poDate;
    // },
  },
  beforeDestroy() {
    this.$store.commit("dnReturn/setTax", {});
  },
};
</script>
