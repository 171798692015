<template>
  <div class="flex flex-col gap-3">
    <!-- <h4>Header</h4> -->
    <div class="flex flex-col gap-4 w-full">
      <!-- <div class="flex flex-col gap-2 w-full sm:w-2/3">
        <span>Tax Rate</span>
        <vs-input v-model="taxRate" class="w-full" @keyup="formatTax" />
      </div>
      <div class="flex flex-col gap-3 w-full sm:w-2/3">
        <span>Ref AP Invoice</span>
        <vs-input v-model="refAPInvoice" class="w-full" />
      </div> -->
      <div class="flex flex-col gap-2 w-full sm:w-2/3">
        <div class="flex flex-col gap-3">
          <span>Tax Rate</span>
          <multiselect
            v-model="selectedTaxRate"
            :options="taxOptions"
            placeholder="Type to search"
            label="Name"
            :allow-empty="true"
            :group-select="false"
            track-by="ID"
            @search-change="onTaxesChange"
            :max-height="125"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <span class="option__title">{{ props.option.Name }}</span>
                </span>
              </div>
            </template>
          </multiselect>
          <!-- <span>Tax Rate</span>
          <span>:</span>
          <span class="font-bold">{{ taxRateNames.join(", ") }}</span> -->
        </div>
      </div>
      <vs-table
        :data="supplierReturnDetails"
        class="mt-6"
        v-if="this.formDetailModels.length > 0"
      >
        <template slot="thead">
          <vs-th style="width: 40%"
            ><vs-checkbox v-model="checkedAll">Check All</vs-checkbox></vs-th
          >
          <vs-th> GRR Code </vs-th>
          <vs-th> Item Name </vs-th>
          <vs-th> Return Qty </vs-th>
          <vs-th> Unit </vs-th>
          <vs-th> Price </vs-th>
          <vs-th> Return Value </vs-th>
          <!-- <vs-th> Tax Rate </vs-th> -->
          <vs-th> Return Tax </vs-th>
          <vs-th> Return Total </vs-th>
          <!-- <vs-th> </vs-th> -->
          <vs-th> Qty </vs-th>
          <vs-th> Value </vs-th>
          <vs-th> Tax </vs-th>
          <vs-th> Total </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-checkbox @click="addChecked(tr.id)" :checked="check(tr.id)" />
            </vs-td>
            <vs-td>
              {{ tr.grr_code }}
            </vs-td>
            <vs-td>
              {{ tr.item_name }}
            </vs-td>
            <vs-td>
              {{ tr.remaining_qty }}
            </vs-td>
            <vs-td>
              {{ tr.unit }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.price) }}
            </vs-td>
            <vs-td>
              {{ priceFormat(currentValues[indextr]) }}
            </vs-td>
            <!-- <vs-td>
              {{ `${tr.tax_rate}%` }}
            </vs-td> -->
            <vs-td>
              {{ priceFormat(currentTaxes[indextr]) }}
            </vs-td>
            <vs-td>
              {{ priceFormat(currentTotals[indextr]) }}
            </vs-td>
            <!-- <vs-td> </vs-td> -->
            <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].qty"
                @keyup="format('qty', indextr)"
              />
            </vs-td>
            <!-- <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].value"
                @keyup="format('value', indextr)"
              />
            </vs-td> -->
            <vs-td>
              <vs-input
                v-model="formDetailModels[indextr].value"
                @keyup="format('value', indextr)"
              />
            </vs-td>
            <vs-td>
              <vs-input readonly v-model="formDetailModels[indextr].tax" />
            </vs-td>
            <vs-td>
              <vs-input readonly v-model="formDetailModels[indextr].total" />
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td colspan="8"></vs-td>
            <vs-td>
              <span class="font-bold">Grand Total</span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.qty" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.value" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.tax" />
              </span>
            </vs-td>
            <vs-td>
              <span class="font-bold">
                <vs-input readonly v-model="agrregate.total" />
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supplierReturnDetails: {
      type: Array,
      default: () => [],
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      taxRate: this.formatPrice(0),
      refAPInvoice: "",
      formDetailModels: [],
      checked: [],
      checkedAll: false,
      taxRateNames: [],
      partialTax: 0,
      partialTotal: 0,
      currentTaxes: [],
      currentTotals: [],
      currentValues: [],
      selectedTaxRate: {},
      taxOptions: [],
      agrregate: {
        total: "",
        tax: "",
        value: "",
        qty: "",
      },
      unwatch: null,
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      (state) => state.dnReturn.taxInvoiceRefence,
      (val) => {
        this.selectedTaxRate = this.taxOptions.find((v) => v.ID === val.tax_id);
      }
    );
  },
  beforeDestroy() {
    if (this.unwatch){
      this.unwatch();
    }
  },
  methods: {
    format(key, index) {
      let data = this.formDetailModels[index][key];

      if (key === "qty") {
        // data = data.replace(/,/g, "");
        // const tempFloat = parseInt(data);
        // const maxQty = parseInt(this.supplierReturnDetails[index].qty);
        // if (tempFloat > maxQty) {
        //   this.formDetailModels[index][key] = this.formatQty(maxQty);
        // } else {
        //   this.formDetailModels[index][key] = this.formatQty(data);
        // }
        this.formDetailModels[index][key] = this.formatQty(data);
      } else {
        this.formDetailModels[index][key] = this.formatPrice(data);
      }

      if (key === "qty") {
        const qty = parseInt(
          this.formDetailModels[index].qty
            ? this.formDetailModels[index].qty
            : 0
        );

        if (qty > 0) {
          const taxRate = this.selectedTaxRate.Rate;
          const price = parseFloat(this.supplierReturnDetails[index].price);
          // const maxQty = parseInt(this.supplierReturnDetails[index].qty);
          const value = qty * price;
          this.formDetailModels[index].value = this.priceFormat(value);

          // const tax =
          //   (parseFloat(this.supplierReturnDetails[index].tax_rate) / 100) *
          //   value;

          const tax = Math.floor((taxRate / 100) * value);
          console.log("TAX INI GES", tax);

          this.formDetailModels[index].tax = this.priceFormat(tax);

          const valueTemp = parseFloat(
            value.toString().replace(/[^0-9.]/g, "")
          );
          const total = valueTemp + tax;
          this.formDetailModels[index].total = this.priceFormat(total);
        } else {
          this.formDetailModels[index].value = this.priceFormat(0);
          this.formDetailModels[index].total = this.priceFormat(0);
          this.formDetailModels[index].tax = this.priceFormat(0);
        }
      } else if (key === "value") {
        const taxRate = this.selectedTaxRate.Rate;
        const value = parseFloat(
          this.formDetailModels[index].value
            ? this.formDetailModels[index].value.replace(/[^0-9.]/g, "")
            : 0
        );

        // const tax =
        //   (parseFloat(this.supplierReturnDetails[index].tax_rate) / 100) *
        //   value;

        const tax = Math.floor((taxRate / 100) * value);

        this.formDetailModels[index].tax = this.priceFormat(tax);

        const total = value + tax;
        this.formDetailModels[index].total = this.priceFormat(total);
      }
      this.calculateAggregate();
    },
    formatTax() {
      let data = this.taxRate;
      this.taxRate = this.formatPrice(data);
    },
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/[^.\d]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatQty(angka) {
      return angka
        .toString()
        .replace(/[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onQtyChange(qty, index) {
      const maxQty = this.supplierReturnDetails[index].qty;
      const qtyTemp = parseInt(qty.replace(/[^0-9]/g, ""));
      if (qtyTemp > maxQty) {
        this.formDetailModels[index].qty = this.priceFormat(maxQty);
      } else {
        this.formDetailModels[index].qty = this.priceFormat(qtyTemp);
      }
    },
    addChecked(val) {
      if (this.checked.includes(val)) {
        const index = this.checked.indexOf(val);
        this.checked.splice(index, 1);
      } else {
        this.checked.push(val);
      }
      console.log("chekced", this.checked);
    },
    check(val) {
      return this.checked.includes(val);
    },
    onTaxesChange(search) {
      if (search.length < 3 && search.length > 0) {
        return;
      }
    },

    async getTaxOptions(search) {
      try {
        const resp = await this.$http.get("/api/v1/master/tax", {
          params: {
            search,
            order: "id",
            sort: "desc",
            length: 20,
          },
        });
        if (resp.code < 299) {
          this.taxOptions = resp.data.records;
          console.log("option", this.taxOptions);
          console.log("supplier", this.supplierReturnDetails[0].tax_name);
          this.selectedTaxRate = this.taxOptions.find(
            (v) => v.Name === this.supplierReturnDetails[0].tax_name
          );
          console.log("selected", this.selectedTaxRate);
        } else {
          this.taxOptions = [];
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-square",
          });
        }
        this.taxOptions = resp.data.records;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },

    calculateAggregate() {
      let aggregateTotal = 0;
      let aggregateTax = 0;
      let aggregateValue = 0;
      let aggregateQty = 0;

      // this.formDetailModels.forEach((line) => {
      //   const qty = parseInt(line.qty.replace(/[^0-9]/g, ""));
      //   const value = parseFloat(line.value.replace(/[^0-9.]/g, ""));
      //   const tax = parseFloat(line.tax.replace(/[^0-9.]/g, ""));
      //   const total = parseFloat(line.total.replace(/[^0-9.]/g, ""));

      //   aggregateTotal += total;
      //   aggregateTax += tax;
      //   aggregateValue += value;
      //   aggregateQty += qty;
      // });

      for (let index = 0; index < this.formDetailModels.length; index++) {
        if (!this.checked.includes(this.formDetailModels[index].id)) {
          continue;
        }
        const qty = parseInt(
          this.formDetailModels[index].qty
            ? this.formDetailModels[index].qty.replace(/[^0-9]/g, "")
            : 0
        );
        const value = parseFloat(
          this.formDetailModels[index].value
            ? this.formDetailModels[index].value.replace(/[^0-9.]/g, "")
            : 0
        );
        const tax = parseFloat(
          this.formDetailModels[index].tax
            ? this.formDetailModels[index].tax.replace(/[^0-9.]/g, "")
            : 0
        );
        const total = parseFloat(
          this.formDetailModels[index].total
            ? this.formDetailModels[index].total.replace(/[^0-9.]/g, "")
            : 0
        );

        aggregateTotal += total;
        aggregateTax += tax;
        aggregateValue += value;
        aggregateQty += qty;
      }

      this.agrregate.total = this.priceFormat(aggregateTotal);
      this.agrregate.tax = this.priceFormat(aggregateTax);
      this.agrregate.value = this.priceFormat(aggregateValue);
      this.agrregate.qty = this.formatQty(aggregateQty);
    },
  },

  async mounted() {
    let tempTaxName = [];
    await this.getTaxOptions("B");
    let total = 0;
    let tax = 0;
    let value = 0;
    let aggregateQty = 0;
    this.formDetailModels = this.supplierReturnDetails.map((detail) => {
      const currentValue =
        parseInt(detail.remaining_qty) * parseFloat(detail.price);

      const currentTax = Math.floor(
        (parseFloat(this.selectedTaxRate.Rate) / 100) * parseFloat(currentValue)
      );
      const currentTotal = currentValue + currentTax;

      const temp = {
        id: detail.id,
        qty: this.formatQty(detail.remaining_qty),
        remaining_qty: this.formatQty(detail.remaining_qty),
        value: this.priceFormat(currentValue),
        tax: this.priceFormat(currentTax),
        total: this.priceFormat(currentTotal),
      };

      total += parseFloat(currentTotal);
      tax += parseFloat(currentTax);
      value += parseFloat(currentValue);
      aggregateQty += parseInt(detail.remaining_qty);

      this.checked.push(detail.id);
      tempTaxName.push(detail.tax_name);

      this.currentTaxes.push(currentTax);
      this.currentTotals.push(currentTotal);
      this.currentValues.push(currentValue);
      return temp;
    });

    this.agrregate.total = this.priceFormat(total);
    this.agrregate.tax = this.priceFormat(tax);
    this.agrregate.value = this.priceFormat(value);
    this.agrregate.qty = this.formatQty(aggregateQty);

    tempTaxName = new Set(tempTaxName);
    this.taxRateNames = Array.from(tempTaxName);
  },
  watch: {
    isSubmit() {
      if (this.isSubmit) {
        const temp = {
          taxRate: this.selectedTaxRate.Rate,
          taxID: this.selectedTaxRate.ID,
          refAPInvoice: this.refAPInvoice,
          // details: this.formDetailModels,
        };
        const details = [];
        for (const id of this.checked) {
          console.log("id to find", id);
          const temp = this.formDetailModels.find((v) => v.id === id);
          if (temp) {
            details.push(temp);
          }
        }
        temp.details = details;
        this.$emit("updateChecked", this.checked);
        this.$emit("updateModel", temp);

        // this.$emit("resetIsSubmit");
      }
    },
    checkedAll() {
      if (this.checkedAll) {
        this.checked = this.supplierReturnDetails.map((v) => v.id);
      } else {
        this.checked = [];
      }
    },
    selectedTaxRate() {
      this.$nextTick(() => {
        for (let index = 0; index < this.formDetailModels.length; index++) {
          this.format("qty", index);
        }
      });
    },
    checked() {
      console.log("checked", this.checked);
      this.calculateAggregate();
    },
  },
};
</script>
